require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./functions/0d21bcaaf9225eaf120e4006b08f507d/code.js', () => { 

BackendlessUI.Functions.Custom['fn_0d21bcaaf9225eaf120e4006b08f507d'] = async function fn_0d21bcaaf9225eaf120e4006b08f507d(listToModify) {
  await (async function(array) {
  	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

  	_.reverse(array)
  })(listToModify);

}

})
define('./functions/0ea1d65b7a2db5c48adc82f4f78b00c1/code.js', () => { 

BackendlessUI.Functions.Custom['fn_0ea1d65b7a2db5c48adc82f4f78b00c1'] = async function fn_0ea1d65b7a2db5c48adc82f4f78b00c1(listsToInspect, comparatorFunction) {
return (await (async function(arrays, comparator) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.xorWith(...arrays, comparator)
})(listsToInspect, comparatorFunction))
}

})
define('./functions/1599aa28f14b94240e844d2a93da1f7c/code.js', () => { 

BackendlessUI.Functions.Custom['fn_1599aa28f14b94240e844d2a93da1f7c'] = function fn_1599aa28f14b94240e844d2a93da1f7c(appDataArg) {
function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(getObjectProperty(appDataArg, 'testProp'));

}

})
define('./functions/15ca0e8cc5e67ed6b62edeb5642db8ca/code.js', () => { 

BackendlessUI.Functions.Custom['fn_15ca0e8cc5e67ed6b62edeb5642db8ca'] = async function fn_15ca0e8cc5e67ed6b62edeb5642db8ca(listToCompact) {
return (await (async function(array) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.compact(array)
})(listToCompact))
}

})
define('./functions/15ceddbd38db9429a0f97a454e8b367a/code.js', () => { 

BackendlessUI.Functions.Custom['fn_15ceddbd38db9429a0f97a454e8b367a'] = async function fn_15ceddbd38db9429a0f97a454e8b367a(listToQuery, numberOfElements) {
return (await (async function(array, n) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	if (n == null) {
	  n = 1
	}

	return _.take(array, n)
})(listToQuery, numberOfElements))
}

})
define('./functions/16424e16271fba19226ae38e9f473baa/code.js', () => { 

BackendlessUI.Functions.Custom['fn_16424e16271fba19226ae38e9f473baa'] = async function fn_16424e16271fba19226ae38e9f473baa(listToQuery) {
return (await (async function(array) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.head(array)
})(listToQuery))
}

})
define('./functions/1b77dd78f5b3ff799d156cab4c43069d/code.js', () => { 

BackendlessUI.Functions.Custom['fn_1b77dd78f5b3ff799d156cab4c43069d'] = async function fn_1b77dd78f5b3ff799d156cab4c43069d(pageData) {
var prices, price, i, selectedTier, baseFee;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  selectedTier = (getObjectProperty(pageData, 'selectedTier'));
  baseFee = (getObjectProperty(pageData, 'baseFee'));
  console.log(baseFee);
  prices = [];
  var i_start = selectedTier * 1;
  var i_inc = 1;
  if (i_start > 14) {
    i_inc = -i_inc;
  }
  for (i = i_start; i_inc >= 0 ? i <= 14 : i >= 14; i += i_inc) {
    price = i * baseFee * (i - selectedTier);
    addItemToList(prices, ({ [`Tier`]: i,[`Price`]: (await (async function(price) {
    	    return new Intl.NumberFormat('en-US', {
    	        style: 'currency',
    	        currency: 'USD',
    	        minimumFractionDigits: 2,
    	        maximumFractionDigits: 2,
    	    }).format(price);
    })(price)),[`twentyFive`]: (await (async function(price) {
    	    return new Intl.NumberFormat('en-US', {
    	        style: 'currency',
    	        currency: 'USD',
    	        minimumFractionDigits: 2,
    	        maximumFractionDigits: 2,
    	    }).format(price);
    })((price * 24 * 7))),[`fifty`]: (await (async function(price) {
    	    return new Intl.NumberFormat('en-US', {
    	        style: 'currency',
    	        currency: 'USD',
    	        minimumFractionDigits: 2,
    	        maximumFractionDigits: 2,
    	    }).format(price);
    })((price * 24 * 15))),[`hundred`]: (await (async function(price) {
    	    return new Intl.NumberFormat('en-US', {
    	        style: 'currency',
    	        currency: 'USD',
    	        minimumFractionDigits: 2,
    	        maximumFractionDigits: 2,
    	    }).format(price);
    })((price * 24 * 30))) }));
  }
  pageData['prices'] = prices;

}

})
define('./functions/1c281acb3a51cd63d152afb639d9a49f/code.js', () => { 

BackendlessUI.Functions.Custom['fn_1c281acb3a51cd63d152afb639d9a49f'] = async function fn_1c281acb3a51cd63d152afb639d9a49f(listToQuery, numberOfElementsToDrop) {
return (await (async function(array, n) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	if (n == null) {
	  n = 1
	}

	return _.dropRight(array, n)
})(listToQuery, numberOfElementsToDrop))
}

})
define('./functions/252e8ac75e86ca1c273b20bc4a3ecbea/code.js', () => { 

BackendlessUI.Functions.Custom['fn_252e8ac75e86ca1c273b20bc4a3ecbea'] = async function fn_252e8ac75e86ca1c273b20bc4a3ecbea(url) {
  await (async function(cssURL) {
  	await new Promise(resolve => {
  	  const link = document.createElement('link')

  	console.log( cssURL )
  	  link.href = cssURL;
  	  link.rel = 'stylesheet'
  	  link.type = 'text/css'
  	  // link.integrity = integrity
  	  // link.crossOrigin = crossOrigin

  	  link.onload = () => resolve()

  	  document.head.appendChild(link);
  	})
  })(url);

}

})
define('./functions/26d5effe010a024af594e7ea0151369e/code.js', () => { 

BackendlessUI.Functions.Custom['fn_26d5effe010a024af594e7ea0151369e'] = async function fn_26d5effe010a024af594e7ea0151369e(listsToInspect, comparatorFunction) {
return (await (async function(arrays, comparator) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.intersectionWith(...arrays, comparator)
})(listsToInspect, comparatorFunction))
}

})
define('./functions/276929d57494d592d6747b8916fd7daa/code.js', () => { 

BackendlessUI.Functions.Custom['fn_276929d57494d592d6747b8916fd7daa'] = async function fn_276929d57494d592d6747b8916fd7daa() {
return (await Backendless.Data.of('Countries').find(Backendless.DataQueryBuilder.create().setPageSize(100)))
}

})
define('./functions/27a3d5b5fe9f5c3e7f0c16ee16cd7b55/code.js', () => { 

BackendlessUI.Functions.Custom['fn_27a3d5b5fe9f5c3e7f0c16ee16cd7b55'] = async function fn_27a3d5b5fe9f5c3e7f0c16ee16cd7b55(propertyNamePathsList, propertyValuesList) {
return (await (async function(props, values) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.zipObjectDeep(props, values)
})(propertyNamePathsList, propertyValuesList))
}

})
define('./functions/2a1538e41c7c2966c613c5082d9cc4a6/code.js', () => { 

BackendlessUI.Functions.Custom['fn_2a1538e41c7c2966c613c5082d9cc4a6'] = async function fn_2a1538e41c7c2966c613c5082d9cc4a6(listOfGroupedElements, combinerFunction) {
return (await (async function(array, iteratee) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.unzipWith(array, iteratee)
})(listOfGroupedElements, combinerFunction))
}

})
define('./functions/2bab1b7406627074e9c73f97d5958322/code.js', () => { 

BackendlessUI.Functions.Custom['fn_2bab1b7406627074e9c73f97d5958322'] = async function fn_2bab1b7406627074e9c73f97d5958322(listToQuery) {
return (await (async function(array) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.initial(array)
})(listToQuery))
}

})
define('./functions/33f4c01b8e8eda17aee5c5294b33b8fc/code.js', () => { 

BackendlessUI.Functions.Custom['fn_33f4c01b8e8eda17aee5c5294b33b8fc'] = async function fn_33f4c01b8e8eda17aee5c5294b33b8fc(listsToProcess) {
return (await (async function(arrays) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.zip(...arrays)
})(listsToProcess))
}

})
define('./functions/352ef07c12218463b71dde2196bfa9dc/code.js', () => { 

BackendlessUI.Functions.Custom['fn_352ef07c12218463b71dde2196bfa9dc'] = async function fn_352ef07c12218463b71dde2196bfa9dc(list) {
return (await (async function(list) {
	return list.map(value => ({ value, sort: Math.random() }))
	    .sort((a, b) => a.sort - b.sort)
	    .map(({ value }) => value)
})(list))
}

})
define('./functions/37a70893d34d348151130eb779d90624/code.js', () => { 

BackendlessUI.Functions.Custom['fn_37a70893d34d348151130eb779d90624'] = async function fn_37a70893d34d348151130eb779d90624(listsToProcess, combinerFunction) {
return (await (async function(arrays, iteratee) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.zipWith(...arrays, iteratee)
})(listsToProcess, combinerFunction))
}

})
define('./functions/38c2ffe50d0e5cca36f20903850cc95a/code.js', () => { 

BackendlessUI.Functions.Custom['fn_38c2ffe50d0e5cca36f20903850cc95a'] = async function fn_38c2ffe50d0e5cca36f20903850cc95a(listToQuery) {
return (await (async function(array) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.last(array)
})(listToQuery))
}

})
define('./functions/3a17163a8ed3566b1d770b133f9e456c/code.js', () => { 

BackendlessUI.Functions.Custom['fn_3a17163a8ed3566b1d770b133f9e456c'] = async function fn_3a17163a8ed3566b1d770b133f9e456c(sortedListToInspect, valueToEvaluate, filterFunction) {
return (await (async function(array, value, iteratee) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.sortedLastIndexBy(array, value, iteratee)
})(sortedListToInspect, valueToEvaluate, filterFunction))
}

})
define('./functions/3b22bf56ea095bef299e80632005db52/code.js', () => { 

BackendlessUI.Functions.Custom['fn_3b22bf56ea095bef299e80632005db52'] = async function fn_3b22bf56ea095bef299e80632005db52(listToProcess, chunkLength) {
return (await (async function(array, size) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	if (size == null) {
	  size = 1
	}

	return _.chunk(array, size)
})(listToProcess, chunkLength))
}

})
define('./functions/3da675c9560df4688c120284991f799e/code.js', () => { 

BackendlessUI.Functions.Custom['fn_3da675c9560df4688c120284991f799e'] = async function fn_3da675c9560df4688c120284991f799e(listToInspect, valuesToExclude, comparatorFunction) {
return (await (async function(array, values, comparator) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.differenceWith(array, values, comparator)
})(listToInspect, valuesToExclude, comparatorFunction))
}

})
define('./functions/4305c6b3c9678eaff3f213c4ef4213e4/code.js', () => { 

BackendlessUI.Functions.Custom['fn_4305c6b3c9678eaff3f213c4ef4213e4'] = async function fn_4305c6b3c9678eaff3f213c4ef4213e4(listToQuery) {
return (await (async function(array) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.tail(array)
})(listToQuery))
}

})
define('./functions/45e840d979f666875cfc9d9849ef3d28/code.js', () => { 

BackendlessUI.Functions.Custom['fn_45e840d979f666875cfc9d9849ef3d28'] = async function fn_45e840d979f666875cfc9d9849ef3d28(sortedListToInspect, valueToEvaluate) {
return (await (async function(array, value) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.sortedIndex(array, value)
})(sortedListToInspect, valueToEvaluate))
}

})
define('./functions/461d17e665f8b6391823125f6307ab14/code.js', () => { 

BackendlessUI.Functions.Custom['fn_461d17e665f8b6391823125f6307ab14'] = async function fn_461d17e665f8b6391823125f6307ab14(listToInspect) {
return (await (async function(array) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.sortedUniq(array)
})(listToInspect))
}

})
define('./functions/4798cbedf1324877d971e7564c23df15/code.js', () => { 

BackendlessUI.Functions.Custom['fn_4798cbedf1324877d971e7564c23df15'] = async function fn_4798cbedf1324877d971e7564c23df15(text, length, ellipsis) {
return (await (async function(text, length, ellipsis) {
	length = length ?? 10
	ellipsis = ellipsis ?? '…'

	if (!(typeof text === 'string' || text instanceof String)) {
	  console.error(`expecting a string for the "text" argument, ${typeof text} provided`)

	  return ''
	}

	if (isNaN(length) || length < 0) {
	  console.error('length must be valid positive numbers')

	  return
	}

	if (!(typeof ellipsis === 'string')) {
	  console.error(`expecting a string for the "ellipsis" argument, ${typeof text} provided`)

	  return ''
	}

	if (text.length <= length) {
	  return text
	}

	return `${text.substr(0, length)}${ellipsis}`

})(text, length, ellipsis))
}

})
define('./functions/486ef78363267883c1df0cc331fd1a59/code.js', () => { 

BackendlessUI.Functions.Custom['fn_486ef78363267883c1df0cc331fd1a59'] = async function fn_486ef78363267883c1df0cc331fd1a59(listToInspect, valuesToExclude) {
return (await (async function(array, values) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.without(array, ...values)
})(listToInspect, valuesToExclude))
}

})
define('./functions/4cf12a1449b890f2c42b8fe2a753bc14/code.js', () => { 

BackendlessUI.Functions.Custom['fn_4cf12a1449b890f2c42b8fe2a753bc14'] = async function fn_4cf12a1449b890f2c42b8fe2a753bc14(sortedListToInspect, valueToEvaluate, filterFunction) {
return (await (async function(array, value, iteratee) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.sortedIndexBy(array, value, iteratee)
})(sortedListToInspect, valueToEvaluate, filterFunction))
}

})
define('./functions/520e5b11f44ee8f88266875f0285766e/code.js', () => { 

BackendlessUI.Functions.Custom['fn_520e5b11f44ee8f88266875f0285766e'] = async function fn_520e5b11f44ee8f88266875f0285766e(propertyNamesList, propertyValuesList) {
return (await (async function(props, values) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.zipObject(props, values)
})(propertyNamesList, propertyValuesList))
}

})
define('./functions/52fdb524073bbad39dd107b4c7541e2d/code.js', () => { 

BackendlessUI.Functions.Custom['fn_52fdb524073bbad39dd107b4c7541e2d'] = async function fn_52fdb524073bbad39dd107b4c7541e2d(listToQuery, valuePickerFunction) {
return (await (async function(array, predicate) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.takeWhile(array, predicate)
})(listToQuery, valuePickerFunction))
}

})
define('./functions/56599adc54c74c9fdbc667193c854a77/code.js', () => { 

BackendlessUI.Functions.Custom['fn_56599adc54c74c9fdbc667193c854a77'] = async function fn_56599adc54c74c9fdbc667193c854a77(listToQuery, numberOfElementsToDrop) {
return (await (async function(array, n) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	if (n == null) {
	  n = 1
	}

	return _.drop(array, n)
})(listToQuery, numberOfElementsToDrop))
}

})
define('./functions/56884591a118bc7af84daa6bfc0ea942/code.js', () => { 

BackendlessUI.Functions.Custom['fn_56884591a118bc7af84daa6bfc0ea942'] = async function fn_56884591a118bc7af84daa6bfc0ea942(listToModify, valuesToRemove, filterFunction) {
  await (async function(array, values, iteratee) {
  	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

  	_.pullAllBy(array, values, iteratee)
  })(listToModify, valuesToRemove, filterFunction);

}

})
define('./functions/58ac96052b6cbe4266b47f18e18aecef/code.js', () => { 

BackendlessUI.Functions.Custom['fn_58ac96052b6cbe4266b47f18e18aecef'] = async function fn_58ac96052b6cbe4266b47f18e18aecef(listToInspect, valueToSearchFor) {
return (await (async function(array, value) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.sortedLastIndexOf(array, value)
})(listToInspect, valueToSearchFor))
}

})
define('./functions/58b599fc79a1984718ac68445dc7b9e6/code.js', () => { 

BackendlessUI.Functions.Custom['fn_58b599fc79a1984718ac68445dc7b9e6'] = async function fn_58b599fc79a1984718ac68445dc7b9e6(listToFill, valueToFillListWith, startPosition, endPosition) {
  await (async function(array, value, start, end) {
  	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

  	if (start == null) {
  	  start = 0
  	}
  	if (end == null) {
  	  end = array.length
  	}

  	_.fill(array, value, start, end)
  })(listToFill, valueToFillListWith, startPosition, endPosition);

}

})
define('./functions/58db38bddf2dcf8f42d5e5214a01acc1/code.js', () => { 

BackendlessUI.Functions.Custom['fn_58db38bddf2dcf8f42d5e5214a01acc1'] = async function fn_58db38bddf2dcf8f42d5e5214a01acc1(listOfGroupedElements) {
return (await (async function(array) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.unzip(array)
})(listOfGroupedElements))
}

})
define('./functions/5aa80c3936bd19c8f14dd135542b64be/code.js', () => { 

BackendlessUI.Functions.Custom['fn_5aa80c3936bd19c8f14dd135542b64be'] = async function fn_5aa80c3936bd19c8f14dd135542b64be(listsTonspect, filterFunction) {
return (await (async function(arrays, iteratee) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.xorBy(...arrays, iteratee)
})(listsTonspect, filterFunction))
}

})
define('./functions/601f96e6c6e413ff585a05b78783325d/code.js', () => { 

BackendlessUI.Functions.Custom['fn_601f96e6c6e413ff585a05b78783325d'] = async function fn_601f96e6c6e413ff585a05b78783325d(listsToInspect, comparatorFunction) {
return (await (async function(arrays, comparator) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.unionWith(...arrays, comparator)
})(listsToInspect, comparatorFunction))
}

})
define('./functions/74c621ab98a12163bece4cad7be292b9/code.js', () => { 

BackendlessUI.Functions.Custom['fn_74c621ab98a12163bece4cad7be292b9'] = async function fn_74c621ab98a12163bece4cad7be292b9(listToConcatenate, valuesToConcatenate) {
return (await (async function(array, values) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.concat(array, ...values)
})(listToConcatenate, valuesToConcatenate))
}

})
define('./functions/7508d2443aea24ba74d73b27671a9829/code.js', () => { 

BackendlessUI.Functions.Custom['fn_7508d2443aea24ba74d73b27671a9829'] = async function fn_7508d2443aea24ba74d73b27671a9829(listToModify, indexesOfElementsToRemove) {
return (await (async function(array, indexes) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.pullAt(array, ...indexes)
})(listToModify, indexesOfElementsToRemove))
}

})
define('./functions/77bed231064ec0e2a7d4ac07f751667a/code.js', () => { 

BackendlessUI.Functions.Custom['fn_77bed231064ec0e2a7d4ac07f751667a'] = async function fn_77bed231064ec0e2a7d4ac07f751667a(listToInspect, functionInvokedPerIteration, indexToSearchFrom) {
return (await (async function(array, predicate, fromIndex) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	if (fromIndex == null) {
	  fromIndex = 0
	}

	return _.findIndex(array, predicate, fromIndex)
})(listToInspect, functionInvokedPerIteration, indexToSearchFrom))
}

})
define('./functions/79a6f77258336011dee2ccdfcf062c76/code.js', () => { 

BackendlessUI.Functions.Custom['fn_79a6f77258336011dee2ccdfcf062c76'] = async function fn_79a6f77258336011dee2ccdfcf062c76(listToInspect, valuesToExclude, filterFunction) {
return (await (async function(array, values, iteratee) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.differenceBy(array, values, iteratee)
})(listToInspect, valuesToExclude, filterFunction))
}

})
define('./functions/7bd98f3e8e1d5a6571e78cd4492f75a8/code.js', () => { 

BackendlessUI.Functions.Custom['fn_7bd98f3e8e1d5a6571e78cd4492f75a8'] = async function fn_7bd98f3e8e1d5a6571e78cd4492f75a8(listToModify, valuesToRemove, comparatorFunction) {
  await (async function(array, values, comparator) {
  	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

  	_.pullAllWith(array, values, comparator)
  })(listToModify, valuesToRemove, comparatorFunction);

}

})
define('./functions/7e649ea1525e52829e82c43fc4f84e2f/code.js', () => { 

BackendlessUI.Functions.Custom['fn_7e649ea1525e52829e82c43fc4f84e2f'] = async function fn_7e649ea1525e52829e82c43fc4f84e2f(listToFlatten) {
return (await (async function(array) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.flatten(array)
})(listToFlatten))
}

})
define('./functions/84c352dcf0d18e7fa2ca41bb0fa92c91/code.js', () => { 

BackendlessUI.Functions.Custom['fn_84c352dcf0d18e7fa2ca41bb0fa92c91'] = async function fn_84c352dcf0d18e7fa2ca41bb0fa92c91(listsToInspect, filterFunction) {
return (await (async function(arrays, iteratee) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.intersectionBy(...arrays, iteratee)
})(listsToInspect, filterFunction))
}

})
define('./functions/854e825608123b86707265b1cfb74f87/code.js', () => { 

BackendlessUI.Functions.Custom['fn_854e825608123b86707265b1cfb74f87'] = async function fn_854e825608123b86707265b1cfb74f87(listToInspect, filterFunction) {
return (await (async function(array, iteratee) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.sortedUniqBy(array, iteratee)
})(listToInspect, filterFunction))
}

})
define('./functions/8c6afca1481cdc048f1c296a77be1975/code.js', () => { 

BackendlessUI.Functions.Custom['fn_8c6afca1481cdc048f1c296a77be1975'] = async function fn_8c6afca1481cdc048f1c296a77be1975(listToFlatten) {
return (await (async function(array) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.flattenDeep(array)
})(listToFlatten))
}

})
define('./functions/8f4b34774e84b8b3681c852e49ed1ac5/code.js', () => { 

BackendlessUI.Functions.Custom['fn_8f4b34774e84b8b3681c852e49ed1ac5'] = async function fn_8f4b34774e84b8b3681c852e49ed1ac5(listToSlice, startPosition, endPosition) {
return (await (async function(array, start, end) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	if (start == null) {
	  start = 0
	}

	if (end == null) {
	  end = array.length
	}

	return _.slice(array, start, end)
})(listToSlice, startPosition, endPosition))
}

})
define('./functions/9458f55be4fb02eee2e72e0b6b1c347c/code.js', () => { 

BackendlessUI.Functions.Custom['fn_9458f55be4fb02eee2e72e0b6b1c347c'] = async function fn_9458f55be4fb02eee2e72e0b6b1c347c(listToInspect, filterFunction) {
return (await (async function(array, iteratee) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.uniqBy(array, iteratee)
})(listToInspect, filterFunction))
}

})
define('./functions/95a8a1ed4f6da56382e43a6149f25d0c/code.js', () => { 

BackendlessUI.Functions.Custom['fn_95a8a1ed4f6da56382e43a6149f25d0c'] = async function fn_95a8a1ed4f6da56382e43a6149f25d0c(listToQuery, valuePickerFunction) {
return (await (async function(array, predicate) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.takeRightWhile(array, predicate)
})(listToQuery, valuePickerFunction))
}

})
define('./functions/9743fcc6f0cc18c8ec28a5f4d0d17e34/code.js', () => { 

BackendlessUI.Functions.Custom['fn_9743fcc6f0cc18c8ec28a5f4d0d17e34'] = async function fn_9743fcc6f0cc18c8ec28a5f4d0d17e34(listToModify, valuePickerFunction) {
return (await (async function(array, predicate) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.remove(array, predicate)
})(listToModify, valuePickerFunction))
}

})
define('./functions/9b1c80153bf186e488630fde34488cde/code.js', () => { 

BackendlessUI.Functions.Custom['fn_9b1c80153bf186e488630fde34488cde'] = function fn_9b1c80153bf186e488630fde34488cde(pageData, logMessage) {
var pageData;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  pageData['log'] = ([(getObjectProperty(pageData, 'log')),logMessage,'\n'].join(''));

}

})
define('./functions/9ef225438f774527c9eb1feb6044ce26/code.js', () => { 

BackendlessUI.Functions.Custom['fn_9ef225438f774527c9eb1feb6044ce26'] = async function fn_9ef225438f774527c9eb1feb6044ce26(listsToInspect, filterFunction) {
return (await (async function(arrays, iteratee) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.unionBy(...arrays, iteratee)
})(listsToInspect, filterFunction))
}

})
define('./functions/a1856c74d27745ecf1c18a8269e6103d/code.js', () => { 

BackendlessUI.Functions.Custom['fn_a1856c74d27745ecf1c18a8269e6103d'] = async function fn_a1856c74d27745ecf1c18a8269e6103d(listToInspect, functionInvokedPerIteration, indexToSearchFrom) {
return (await (async function(array, predicate, fromIndex) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	if (fromIndex == null) {
	    fromIndex = array.length - 1
	}

	return _.findLastIndex(array, predicate, fromIndex)
})(listToInspect, functionInvokedPerIteration, indexToSearchFrom))
}

})
define('./functions/a376d7d6007e9dbebe6eea15750b4b61/code.js', () => { 

BackendlessUI.Functions.Custom['fn_a376d7d6007e9dbebe6eea15750b4b61'] = async function fn_a376d7d6007e9dbebe6eea15750b4b61(listToInspect, comparatorFunction) {
return (await (async function(array, comparator) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.uniqWith(array, comparator)
})(listToInspect, comparatorFunction))
}

})
define('./functions/a868a6a4f6678dfcdaf2fbd8ee89f031/code.js', () => { 

BackendlessUI.Functions.Custom['fn_a868a6a4f6678dfcdaf2fbd8ee89f031'] = async function fn_a868a6a4f6678dfcdaf2fbd8ee89f031(listToModify, valuesToRemove) {
  await (async function(array, values) {
  	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

  	_.pullAll(array, values)
  })(listToModify, valuesToRemove);

}

})
define('./functions/b10683b89d3a8232328807d905aa7ecc/code.js', () => { 

BackendlessUI.Functions.Custom['fn_b10683b89d3a8232328807d905aa7ecc'] = async function fn_b10683b89d3a8232328807d905aa7ecc(sortedListToInspect, valueToEvaluate) {
return (await (async function(array, value) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.sortedLastIndex(array, value)
})(sortedListToInspect, valueToEvaluate))
}

})
define('./functions/b55a0b2dad43d733ad63285e237240c8/code.js', () => { 

BackendlessUI.Functions.Custom['fn_b55a0b2dad43d733ad63285e237240c8'] = async function fn_b55a0b2dad43d733ad63285e237240c8(listToQuery, filterFunction) {
return (await (async function(array, predicate) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.dropRightWhile(array, predicate)
})(listToQuery, filterFunction))
}

})
define('./functions/b665c075565aa28862ac1e9374ca2b96/code.js', () => { 

BackendlessUI.Functions.Custom['fn_b665c075565aa28862ac1e9374ca2b96'] = async function fn_b665c075565aa28862ac1e9374ca2b96(listToQuery, filterFunction) {
return (await (async function(array, predicate) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.dropWhile(array, predicate)
})(listToQuery, filterFunction))
}

})
define('./functions/b8d6305d8b24558eb58f8fe7c0212833/code.js', () => { 

BackendlessUI.Functions.Custom['fn_b8d6305d8b24558eb58f8fe7c0212833'] = async function fn_b8d6305d8b24558eb58f8fe7c0212833(listToFlatten, maxRecursionDepth) {
return (await (async function(array, depth) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	if (depth == null) {
	  depth = 1
	}

	return _.flattenDepth(array, depth)
})(listToFlatten, maxRecursionDepth))
}

})
define('./functions/b93bcd5de56aa56928854fbb554683b1/code.js', () => { 

BackendlessUI.Functions.Custom['fn_b93bcd5de56aa56928854fbb554683b1'] = async function fn_b93bcd5de56aa56928854fbb554683b1(listToQuery, elementIndex) {
return (await (async function(array, n) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	if (n == null) {
	  n = 0
	}

	return _.nth(array, n)
})(listToQuery, elementIndex))
}

})
define('./functions/c1083dfc958bfce9f511282469f18202/code.js', () => { 

BackendlessUI.Functions.Custom['fn_c1083dfc958bfce9f511282469f18202'] = async function fn_c1083dfc958bfce9f511282469f18202(listsToInspect) {
return (await (async function(arrays) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.union(...arrays)
})(listsToInspect))
}

})
define('./functions/c58e08966457701101ab34279f33af6d/code.js', () => { 

BackendlessUI.Functions.Custom['fn_c58e08966457701101ab34279f33af6d'] = async function fn_c58e08966457701101ab34279f33af6d(keyValuePairsList) {
return (await (async function(pairs) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.fromPairs(pairs)
})(keyValuePairsList))
}

})
define('./functions/cab97651610a7df89b05dbc568adb257/code.js', () => { 

BackendlessUI.Functions.Custom['fn_cab97651610a7df89b05dbc568adb257'] = async function fn_cab97651610a7df89b05dbc568adb257(listToInspect, valueToSearchFor, indexToSearchFrom) {
return (await (async function(array, value, fromIndex) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	if (fromIndex == null) {
	  fromIndex = array.length - 1
	}

	return _.lastIndexOf(array, value, fromIndex);
})(listToInspect, valueToSearchFor, indexToSearchFrom))
}

})
define('./functions/cf0cee815db7c56daa3d4a3f0704a8ac/code.js', () => { 

BackendlessUI.Functions.Custom['fn_cf0cee815db7c56daa3d4a3f0704a8ac'] = async function fn_cf0cee815db7c56daa3d4a3f0704a8ac(listsToInspect) {
return (await (async function(arrays) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.intersection(...arrays)
})(listsToInspect))
}

})
define('./functions/d535622b38bb7a25aa31ae757fe6869c/code.js', () => { 

BackendlessUI.Functions.Custom['fn_d535622b38bb7a25aa31ae757fe6869c'] = async function fn_d535622b38bb7a25aa31ae757fe6869c(listToQuery, numberOfElements) {
return (await (async function(array, n) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	if (n == null) {
	  n = 1
	}

	return _.takeRight(array, n)
})(listToQuery, numberOfElements))
}

})
define('./functions/d697bd8f237e027b27f73442d3dd938d/code.js', () => { 

var blocks, i;

function mathRandomInt(a, b) {
  if (a > b) {
    // Swap a and b to ensure a is smaller.
    var c = a;
    a = b;
    b = c;
  }
  return Math.floor(Math.random() * (b - a + 1) + a);
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}



BackendlessUI.Functions.Custom['fn_d697bd8f237e027b27f73442d3dd938d'] = async function fn_d697bd8f237e027b27f73442d3dd938d(pageData) {
    blocks = [];
  for (i = 1; i <= 20; i++) {
    addItemToList(blocks, (mathRandomInt(1, 100)));
  }
  pageData['stack'] = blocks;



}

})
define('./functions/d813cd0b8767bda332b7d73f1ea8155b/code.js', () => { 

BackendlessUI.Functions.Custom['fn_d813cd0b8767bda332b7d73f1ea8155b'] = async function fn_d813cd0b8767bda332b7d73f1ea8155b(listToConvert, elementSeparator) {
return (await (async function(array, separator) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.join(array, separator)
})(listToConvert, elementSeparator))
}

})
define('./functions/dfe6f4741626a11429b6777d44ba18e7/code.js', () => { 

BackendlessUI.Functions.Custom['fn_dfe6f4741626a11429b6777d44ba18e7'] = async function fn_dfe6f4741626a11429b6777d44ba18e7(listToInspect, valuesToExclude) {
return (await (async function(array, values) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.difference(array, values)
})(listToInspect, valuesToExclude))
}

})
define('./functions/dfef9391c409b8519db2242d696ca08c/code.js', () => { 

BackendlessUI.Functions.Custom['fn_dfef9391c409b8519db2242d696ca08c'] = async function fn_dfef9391c409b8519db2242d696ca08c(listToInspect) {
return (await (async function(array) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.uniq(array)
})(listToInspect))
}

})
define('./functions/e04783eefccc55279cd3ffc580231f28/code.js', () => { 

BackendlessUI.Functions.Custom['fn_e04783eefccc55279cd3ffc580231f28'] = async function fn_e04783eefccc55279cd3ffc580231f28(listToInspect, valueToSearchFor) {
return (await (async function(array, value) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.sortedIndexOf(array, value)
})(listToInspect, valueToSearchFor))
}

})
define('./functions/eab6964cdd451a9c2dbbbeb2cbcc36de/code.js', () => { 

BackendlessUI.Functions.Custom['fn_eab6964cdd451a9c2dbbbeb2cbcc36de'] = async function fn_eab6964cdd451a9c2dbbbeb2cbcc36de(listToModify, valuesToRemove) {
  await (async function(array, values) {
  	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

  	_.pull(array, ...values)
  })(listToModify, valuesToRemove);

}

})
define('./functions/ec97791f4918eb07b6dff2a5bfec059a/code.js', () => { 

BackendlessUI.Functions.Custom['fn_ec97791f4918eb07b6dff2a5bfec059a'] = async function fn_ec97791f4918eb07b6dff2a5bfec059a(listsToInspect) {
return (await (async function(arrays) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	return _.xor(...arrays)
})(listsToInspect))
}

})
define('./functions/f1c97833e26b0995f236b863812bfed2/code.js', () => { 

BackendlessUI.Functions.Custom['fn_f1c97833e26b0995f236b863812bfed2'] = async function fn_f1c97833e26b0995f236b863812bfed2(listToInspect, valueToSearchFor, indexToSearchFrom) {
return (await (async function(array, value, fromIndex) {
	if (typeof _ === 'undefined' || typeof _.VERSION !== 'string') throw new Error('The Lodash Library is not installed. In the UI Container -> Settings -> Libraries Tab add the JS Library Link: https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')

	if (fromIndex == null) {
	  fromIndex = 0
	}

	return _.indexOf(array, value, fromIndex)
})(listToInspect, valueToSearchFor, indexToSearchFrom))
}

})
define('./pages/404/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var spamDecision;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  spamDecision = (await Backendless.Request.post(`${Backendless.appPath}/services/OOPSpamDetector/score`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'content': 'I am Mr Richard Wahl, the mega winner of $533 Million USD in Mega Millions Jackpot, I\'m donating to 5 random individuals if you get this email then your email was selected after a spin ball. I have spread most of my wealth over a number of charities and organizations.I have voluntarily decided to donate the sum of $2 Million USD to you as one of the selected 5, to verify my winnings please see my youtube website page below.','allowedLanguages': ['en', 'es'],'blockedCountries': ['ru'] }));
  console.log(spamDecision);
  ___arguments.context.pageData['sampleImage'] = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/Unsplash/randomPhoto`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'query': JSON.stringify('drinking coffee') }).send()), 'urls.regular'));

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('', null);

  },  
/* handler:onClick *//* content */
}));

define('./pages/ChartDemo/components/page/bundle.js', [], () => ({
  /* content */

  /* handler:onEnter */
  async onEnter(___arguments) {
      await Backendless.Request['get']((function(url){ if( !url ) { throw new Error('Url must be specified.')} if( !url.startsWith('http://') && !url.startsWith('https://')) { return 'https://' + url } return url})('')).send();

  },  
/* handler:onEnter *//* content */
}));

define('./pages/Cities/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      ___arguments.context.pageData['myCities'] = (await Backendless.Data.of('City').find(Backendless.DataQueryBuilder.create().setPageSize(10)));
  await (async function() {
  	console.log( "Hey, I am JS code")
  })();

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/DatabaseManager/components/page/bundle.js', [], () => ({
  /* content */

  /* handler:onEnter */
  onEnter(___arguments) {
      ___arguments.context.pageData['tableOptions'] = ({ 'title': 'My Table','whereClause': 'objectId != null','realTime': true,'searching': true,'sorting': true,'paging': true,'allowAdd': true,'allowEdit': true,'allowDelete': true });

  },  
/* handler:onEnter *//* content */
}));

define('./pages/LandingDemo/components/c0aac99608223a4d2c840cfa205624fe/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('CountriesPage', null);

  },  
/* handler:onClick *//* content */
}));

define('./pages/LandingDemo/components/10917039d864b60e978d66ccdf219347/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    
  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/Marina_Delete_Please_IF_I_Forgot/components/72d16dbc6ab5def899f7b1f793f28cf5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}


  console.log((await Backendless.Files.saveFile(encodePath('/'), 'h+e+l+l+o.log', 'test', false)));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Marina_Delete_Please_IF_I_Forgot/components/d3ade3c9d8eb9b514f6294b9b5c1a5fb/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}


  await Backendless.Files.remove(encodePath('https://www.backendless.us/api/files/h+e+l+l+o.log'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/NewTestTable/components/page/bundle.js', [], () => ({
  /* content */

  /* handler:onEnter */
  onEnter(___arguments) {
      ___arguments.context.pageData['tableOptions'] = ({ 'title': 'My Table','whereClause': 'objectId != null','realTime': true,'searching': true,'sorting': true,'paging': true,'allowAdd': true,'allowEdit': true,'allowDelete': true });

  },  
/* handler:onEnter *//* content */
}));

define('./pages/TestPage/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var dataList;


  dataList = (await (async function() {
  	return [
  	  { prop1:1, prop2:"abc", prop3:true},
  	  { prop1:23, prop2:"xyz", prop3:false},
  	  { prop1:777, prop2:"foo", prop3:true}
  	  ]
  })());

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/aggrid/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async onEnter(___arguments) {
    var item, firstColumn;

/**
 * Describe this function...
 */
async function firstColumn2() {
  if (firstColumn) {
    firstColumn = false;
    return true;
  }
  return firstColumn
}


  await BackendlessUI.Functions.Custom['fn_252e8ac75e86ca1c273b20bc4a3ecbea']('https://unpkg.com/ag-grid-community/dist/styles/ag-grid.css')
  await BackendlessUI.Functions.Custom['fn_252e8ac75e86ca1c273b20bc4a3ecbea']('https://unpkg.com/ag-grid-community/dist/styles/ag-theme-alpine.css')
  firstColumn = true;
  await (async function() {
  	var data = (await Backendless.Data.of('City').find(Backendless.DataQueryBuilder.create().setProperties(['Country.Name', '*']).setPageSize(100)));
  	var columns = (await Promise.all((await Backendless.Data.describe( 'City' )).map(async item => {; return ({ 'field': (item['name']),'sortable': true,'filter': true,'checkboxSelection': await firstColumn2() });})));

  	  const agGrid = await BackendlessUI.requireModule( "https://unpkg.com/ag-grid-community/dist/ag-grid-community.min.noStyle.js" )
  	  const eGridDiv = document.querySelector('#myGrid');
  	  const gridOptions = {
  	      columnDefs: columns,
  	      rowData: data
  	    };
  	  new agGrid.Grid(eGridDiv, gridOptions);
  })();

  },  
  /* handler:onEnter */
  /* content */
}))

define('./pages/agrosoko/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async onEnter(___arguments) {
      if ((function(){ try { return JSON.parse(localStorage.getItem('localuser')) } catch(e){ return null }})()) {
    ___arguments.context.appData['currentUser'] = ((function(){ try { return JSON.parse(localStorage.getItem('localuser')) } catch(e){ return null }})());
    ___arguments.context.appData['currentCompany'] = ((function(){ try { return JSON.parse(localStorage.getItem('localCompany')) } catch(e){ return null }})());
  } else {
    ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('Login', null);
  }
  ___arguments.context.pageData['currentUser'] = (___arguments.context.appData['currentUser']);
  ___arguments.context.pageData['currentCompany'] = (___arguments.context.appData['currentCompany']);

  },  
  /* handler:onEnter */
  /* content */
}))

define('./pages/agrosoko/components/ea41aa65e47dc19c85d8291b309f93ec/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('NewOrder', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/agrosoko/components/21e150017dd24c1500cb3f2647a60f9a/bundle.js', [], () => ({
  /* content */
  /* handler:onWhereClauseAssignment */
  onWhereClauseAssignment(___arguments) {
      return (['buyer.objectId=\'',((___arguments.context.appData['currentUser'])['objectId']),'\''].join(''))

  },  
  /* handler:onWhereClauseAssignment */
  /* content */
}))

define('./pages/agrosoko/components/cea388a1851e518c0b83a86125ae3ccb/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      await Backendless.UserService.logout();
  localStorage.removeItem('localuser');
  localStorage.removeItem('localCompany');
  ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('Login', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/agrosoko/components/f9bfaf22b0dea18ef337238679d2d5d4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('Dashboard', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/agrosoko/components/4194fcf29284e74c04d80f8b0e8bfb1c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('CompanyDetails', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/agrosoko/components/ea135a9d1cba0046bbc8c3a4729838ca/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('AccountDetails', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/agrosoko/components/c42e03fab2119e069295d122cccf990c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('AccountDetails', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/agrosoko/components/337cc0df533b5a140f880695292b6d07/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('Marketplace', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/agrosoko/components/9429a11f824c02e91d1b331746b7943a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('Contact', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/agrosoko/components/030df516062110b55021ab6892a51376/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('Marketplace', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/agrosoko/components/bced5f8f9f2170cc14a7c23f53861c31/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('Contact', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/agrosoko/components/f69964e77bb6c693c0baf1549878f891/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      await Backendless.UserService.logout();
  localStorage.removeItem('localuser');
  localStorage.removeItem('localCompany');
  ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('Login', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/agrosoko/components/54790402a0fdb194c26f65589d2bbfe8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('NewOrder', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/agrosoko/components/d5fc4268b7178607a1652b378371c65c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('NewOrder', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/basicDemo/components/page/bundle.js', [], () => ({
  /* content */

  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      await Backendless.Data.of('Person').save( ({ [`firstName`]: 'Bob',[`objectId`]: '0C2CD8BE-7175-4868-B126-0E8A4DCD807F' }) );

  },
  /* handler:onEnter */
  /* content */
}));

define('./pages/blank/components/d744ec856b5b623e3f188036248bacc2/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  onBeforeMount(___arguments) {
    
  },  
  /* handler:onBeforeMount */
  /* content */
}))

define('./pages/blankPage/components/d2aa4d7b2f075b81c4b45e1f4e09e00e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['isPasswordVisible'] = (!(___arguments.context.pageData['isPasswordVisible']));
  ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('ad412b013c0f9f7e8666d49b92b594ab'))['type'] = ((___arguments.context.pageData['isPasswordVisible']) ? 'input' : 'password');

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/blankPage/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var chatGPTSession;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  chatGPTSession = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/OpenAI/session`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send()), 'sessionId'));
  console.log(chatGPTSession);

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/blockRendering/components/page/bundle.js', [], () => ({
  /* content */

  /* handler:onEnter */
  async onEnter(___arguments) {
    var items;


  items = (await Backendless.Data.of('CountryLanguage').find(Backendless.DataQueryBuilder.create().setWhereClause('').setPageSize(100)));
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('900d383852781fcab2bcfe3cbbefbecc', [items[0], items[1], items[2]]);

  },
  /* handler:onEnter *//* content */
}));

define('./pages/blockRendering/components/b8ad2ddc7b963933b5668cf8eca568f7/bundle.js', [], () => ({
  /* content */

  




/* handler:onBeforeMount */
  onBeforeMount(___arguments) {
      console.log('====');
  console.log(___arguments.context.dataModel);
  console.log(___arguments.context.dataModel['Percentage']);
  console.log((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('b8ad2ddc7b963933b5668cf8eca568f7'));
  console.log(JSON.stringify(((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('b8ad2ddc7b963933b5668cf8eca568f7'))));
  console.log('-----------');
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('b8ad2ddc7b963933b5668cf8eca568f7'))['width'] = (String(___arguments.context.dataModel['Percentage']) + String('%'));

  },
  /* handler:onBeforeMount *//* content */
}));

define('./pages/blockRendering/components/28e77d8d214ef52f00096e5c8429fe71/bundle.js', [], () => ({
  /* content */

  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    
  },  
/* handler:onDisplayAssignment *//* content */
}));

define('./pages/blockRendering/components/92527d76069a0aefcd4e6d75ff9c2c5f/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  onClick(___arguments) {
    
  },  
/* handler:onClick *//* content */
}));

define('./pages/chat/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var command, nickName;

function generateUUID() {
   const chr4 = () => Math.random().toString(16).slice(-4);
   const chr8 = () => `${chr4()}${chr4()}`;
   const short = () => chr8();
   const long = () => `${chr8()}-${chr4()}-${chr4()}-${chr4()}-${chr8()}${chr4()}`;

   return { short, long, }
}

/**
 * this function generates a unique ID that identifies this
 * current user. The ID is then used to decide if the name
 * of the current user is displayed for their own messages
 */
async function generate_My_ID() {
  ___arguments.context.pageData['myID'] = (generateUUID().long());
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function getChannelInstance(channelName) { const channelsMap = getGlobalEntitiesMap('channels');if(!channelsMap[channelName] || !channelsMap[channelName].connection) { channelsMap[channelName] = Backendless.Messaging.subscribe(channelName); }return channelsMap[channelName];}

/**
 * This is the main "engine" for receiving chat messages and adding
 * them to the repeater that renders them. Any important thing
 * that happens here is "linking" the new incoming message to the
 * previous one in the list. This is needed to determine (later)
 * if the name of the sender should be displayed for this message
 */
async function subscribe_to_receive_chat_messages() {

  ;(function() {
    const callback = async command => {
        console.log(command);
    (getObjectProperty(command, 'data'))['prevMessage'] = (!((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('fbaf199bba5204a92d367d9f7c6d6511')).length ? null : ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('fbaf199bba5204a92d367d9f7c6d6511'))[0]);
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('fbaf199bba5204a92d367d9f7c6d6511')).splice(0, 0, (getObjectProperty(command, 'data')));

    };

    const channel = getChannelInstance('chat');

    channel.addCommandListener(callback);


  })();
}

/**
 * Set the chat list to an empty list so the chat
 * starts with an empty collection. New incoming
 * messages are added to the collection in the repeater
 */
async function initialize_chat_list() {
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('fbaf199bba5204a92d367d9f7c6d6511', []);
}

/**
 * Check if the nickname already exists in local storage. If it is there,
 * retrieve and use it. Otherwise, prompt the user to enter their nickname
 */
async function get_nickname_or_ask_for_one() {
  nickName = ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('nickName'));
  if (nickName) {
    ___arguments.context.pageData['nickName'] = nickName;
    ___arguments.context.pageData['showNicknameModal'] = false;
  } else {
    ___arguments.context.pageData['showNicknameModal'] = true;
  }
}


  await generate_My_ID();
  await get_nickname_or_ask_for_one();
  await initialize_chat_list();
  await subscribe_to_receive_chat_messages();

  },
  /* handler:onEnter */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
      ___arguments.context.pageData['showNicknameModal'] = false;

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/chat/components/3b73583da5ea180dcdfe60a88248aa47/bundle.js', [], () => ({
  /* content */
  /* handler:onKeyDown */
  async ['onKeyDown'](___arguments) {
    var unsafe, x;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function getChannelInstance(channelName) { const channelsMap = getGlobalEntitiesMap('channels');if(!channelsMap[channelName] || !channelsMap[channelName].connection) { channelsMap[channelName] = Backendless.Messaging.subscribe(channelName); }return channelsMap[channelName];}

/**
 * Describe this function...
 */
async function escape_special_chars(unsafe) {
  return ((((unsafe.replace((new RegExp('&', 'g')), '&amp;')).replace((new RegExp('<', 'g')), '&lt;')).replace((new RegExp('>', 'g')), '&gt;')).replace((new RegExp('"', 'g')), '&quot;')).replace((new RegExp('\'', 'g')), '&#039;')
}


  if (___arguments.keyCode == 13) {
    await (getChannelInstance('chat')).send('MSG', ({ 'message': await escape_special_chars((getObjectProperty(___arguments.context.pageData, 'message'))),'sender': await escape_special_chars((getObjectProperty(___arguments.context.pageData, 'nickName'))),'myID': (getObjectProperty(___arguments.context.pageData, 'myID')),'time': ((new Date()).toLocaleTimeString()) }));
    ___arguments.context.pageData['message'] = '';
  }

  },
  /* handler:onKeyDown */
  /* content */
}))

define('./pages/chat/components/4e8d9a489e92276172d99bbc708126b3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['showNicknameModal'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/chat/components/fc9fbc9844bfff5b40cef5f613772e41/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!((getObjectProperty(___arguments.context.getComponentDataStoreByUid('73d2bf0c84ed77635eba7b37c80d331b'), 'myID')) == (getObjectProperty(___arguments.context.pageData, 'myID')) || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('73d2bf0c84ed77635eba7b37c80d331b'), 'myID')) == (getObjectProperty(___arguments.context.getComponentDataStoreByUid('73d2bf0c84ed77635eba7b37c80d331b'), 'prevMessage.myID'))))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/chat/components/02d1bf9de425f85ef06f95c47c492311/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('73d2bf0c84ed77635eba7b37c80d331b'), 'myID')) == (getObjectProperty(___arguments.context.pageData, 'myID')) ? ['msg', 'sent'] : ['msg', 'rcvd'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/chat/components/602e198ee1a6009fe075b62fb984c545/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  localStorage.setItem('nickName', JSON.stringify((getObjectProperty(___arguments.context.pageData, 'nickName'))));
  ___arguments.context.pageData['showNicknameModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/chat/components/a0db6aaf2f5e165bc374a45a7e49217e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'showNicknameModal')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/chat/components/ca16acf242a2ca57b75b1246ba450094/bundle.js', [], () => ({
  /* content */
  /* handler:onKeyDown */
  ['onKeyDown'](___arguments) {
      if (___arguments.keyCode == 13) {
    ___arguments.context.pageData['showNicknameModal'] = false;
  }

  },
  /* handler:onKeyDown */
  /* content */
}))

define('./pages/citiesList/components/page/bundle.js', [], () => ({
  /* content */

  /* handler:onEnter */
  async onEnter(___arguments) {
      console.log('page enter');
  console.log(___arguments.context.pageData['countryId']);
  ___arguments.context.pageData['citiesCondition'] = (['Country.objectId = \'',(___arguments.context.pageData['countryId']),'\''].join(''));
  if ((___arguments.context.appData['selectedCountry']) == null) {
    ___arguments.context.appData['selectedCountry'] = (await BackendlessUI.Functions.Custom['getCountry'](___arguments.context.pageData));
  }
  ___arguments.context.pageData['title'] = (String('Cities in ') + String((___arguments.context.appData['selectedCountry'])['Name']));

  },  
/* handler:onEnter *//* content */
}));

define('./pages/citiesList/components/b8f53a9a3213db93e8c2918311332243/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (){ window.history.back(); })();

  },  
/* handler:onClick *//* content */
}));

define('./pages/citiesList/components/340379f2e27e74329a61e6b40b97c628/bundle.js', [], () => ({
  /* content */

  
/* content */
}));

define('./pages/classtest/components/18683bd970ecc0df8fe63aed6867b507/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
      return ['a', 'b']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/cogotoast/components/a6f59607ee9691371a52fbdd69ce5218/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      await (async function() {
  	const cogoToast = await BackendlessUI.requireModule( "modules/cogotoast" )
  	cogoToast.success("Success!");
  })();

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/containerTest/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async onEnter(___arguments) {
      ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('6324237aca9fc933c14e43f908345903', (await Backendless.Data.of('City').find(Backendless.DataQueryBuilder.create().setPageSize(10))));

  },  
  /* handler:onEnter */
  /* content */
}))

define('./pages/containerTest/components/07cd75ed7516e3dcef15cf614472a4a2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    
  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/countries/components/page/bundle.js', [], () => ({
  /* content */

  /* handler:onEnter */
  onEnter(___arguments) {
      ___arguments.context.pageData['tableOptions'] = ({ 'title': 'My Table','whereClause': 'objectId != null','realTime': true,'searching': true,'sorting': true,'paging': true,'allowAdd': true,'allowEdit': true,'allowDelete': true });

  },  
/* handler:onEnter *//* content */
}));

define('./pages/countries/components/130e3e144141665cd61f5397b527cbb5/bundle.js', [], () => ({
  /* content */

  /* handler:onRecordSelect */
  async onRecordSelect(___arguments) {
      ___arguments.context.appData['selectedCountry'] = ___arguments.selectedRecord;
  ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('countryDetails', ({ 'countryId': (___arguments.selectedRecord['objectId']) }));

  },  
/* handler:onRecordSelect *//* content */
}));

define('./pages/countryDetails/components/page/bundle.js', [], () => ({
  /* content */

  
/* handler:onEnter */
  ['onEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  Object.assign(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('8f7d26b2547dc9fd2b6aa93aa5389571')), (getObjectProperty(___arguments.context.appData, 'selectedCountry')));

  },
  /* handler:onEnter *//* content */
}));




define('./pages/countryDetails/components/8f7d26b2547dc9fd2b6aa93aa5389571/bundle.js', [], () => ({
  /* content */

  
/* handler:onDynamicItemsAssignment */
  onDynamicItemsAssignment(___arguments) {
      console.log('in dynamic items logic');

  return ({ 'Name': 'Foo' })

  },  
/* handler:onDynamicItemsAssignment *//* content */
}));

define('./pages/countryDetails/components/4876fe36068d978d34422f5782cd09f8/bundle.js', [], () => ({
  /* content */

  /* handler:onValueAssignment */
  async onValueAssignment(___arguments) {
      return (((await Backendless.Data.of('Country').loadRelations((___arguments.context.getComponentDataStoreByUid('8f7d26b2547dc9fd2b6aa93aa5389571')['objectId']), Backendless.LoadRelationsQueryBuilder.create().setRelationName('Capital').setPageSize(1).setOffset(0)))[0])['Name'])

  },  
/* handler:onValueAssignment *//* content */
}));

define('./pages/countryDetails/components/8866b3ff3feef1c261ac0214912d0df1/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (){ window.history.back(); })();

  },  
/* handler:onClick *//* content */
}));

define('./pages/csv/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async onEnter(___arguments) {
      ___arguments.context.pageData['url'] = (await Backendless.Request.post(`${Backendless.appPath}/services/CSVAdapter/table2csv`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'filePath': 'customers.csv','tableName': 'Customer','tableOptions': ({ 'where': undefined,'properties': ['name', 'phoneNumber'],'sortBy': ['name'],'relations': ['address'] }),'csvOptions': ({ 'header': true,'quoted': true }) }));

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/dataBindingDemo/components/d341063b894bbacca6ab1c17974cac5c/bundle.js', [], () => ({
  /* content */

  /* handler:onContentAssignment */
  onContentAssignment(___arguments) {
      return ((___arguments.context.getComponentDataStoreByUid('685eaab363ee72c4f549b623a458d6fa')['x']) + (___arguments.context.getComponentDataStoreByUid('685eaab363ee72c4f549b623a458d6fa')['y']))

  },  
/* handler:onContentAssignment *//* content */
}));

define('./pages/dataTableTest/components/6d89d926800d9d6e99e95453bc04ec86/bundle.js', [], () => ({
  /* content */

  /* handler:onRecordSelect */
  async onRecordSelect(___arguments) {
      ___arguments.context.appData['selectedRecord'] = ___arguments.selectedRecord;
  ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('landing', null);

  },  
/* handler:onRecordSelect *//* content */
}));

define('./pages/demo/components/0eca69beff4a0be766168e8901ec2b31/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  onBeforeMount(___arguments) {
    
  },  
  /* handler:onBeforeMount */
  /* content */
}))

define('./pages/demo/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var selectedRange;


  await BackendlessUI.Functions.Custom['fn_276929d57494d592d6747b8916fd7daa']();

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/demoPage/components/8e323d8fdc22cc9e0991c74cb791d80d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await Backendless.Request.post(`${Backendless.appPath}/services/CraftMyPdf/generate`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify());

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/endlessrange/components/dec37cf92b516c0b429af1fb413cecf8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      console.log(___arguments['values']);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/hello/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async onEnter(___arguments) {
    var testimonialsCards, starsNumber, j, contactInfo, item, courses;

function listsRepeat(value, n) {
  var array = [];
  for (var i = 0; i < n; i++) {
    array[i] = value;
  }
  return array;
}


  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('ac4a977832adf413fc821584ff0ad028'))['display'] = 'flex';
  ___arguments.context.pageData['btnlabel'] = 'Subscribe';
  courses = (await Backendless.Data.of('Course').find(Backendless.DataQueryBuilder.create().setSortBy('order').setPageSize(10)));
  ___arguments.context.appData['popularCourses'] = (await (async () => {
    async function callback (item) {


      return (item['isFeatured']);
    }

    const list = await Promise.all(courses.map(async source => ({
      source,
      value: await callback(source),
    })));

    const resultList = list.filter(item => item.value)

    return resultList.map(item => item.source)
  })());
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d12239b29d062f6f9d73d30d8d5f6598', (___arguments.context.appData['popularCourses']));
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e75db1f7f7ac6149137c8b9a8aefdd77', (___arguments.context.appData['popularCourses']));
  contactInfo = (await Backendless.Data.of('ContactInfo').find(Backendless.DataQueryBuilder.create().setPageSize(10)))[0];
  ___arguments.context.pageData['address'] = (contactInfo['address']);
  ___arguments.context.pageData['phone'] = (contactInfo['phone']);
  ___arguments.context.pageData['contactEmail'] = (contactInfo['email']);
  ___arguments.context.pageData['socialLinks'] = (contactInfo['socialLinks']);
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('aa966a8dbbca954dc512805e3efbb25d', (___arguments.context.pageData['socialLinks']));
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('bbe89b03620fb3da8b461f16dfc31c31', (await Backendless.Data.of('Feature').find(Backendless.DataQueryBuilder.create().setSortBy('order').setPageSize(10))));
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('07becbf39e93e62f6cc9df59330b7d45', (await Backendless.Data.of('Technology').find(Backendless.DataQueryBuilder.create().setPageSize(10))));
  testimonialsCards = (await Backendless.Data.of('Testimonial').find(Backendless.DataQueryBuilder.create().setSortBy('order').setPageSize(10)));
  for (var j_index in testimonialsCards) {
    j = testimonialsCards[j_index];
    starsNumber = (j['rating']);
    if (starsNumber > 5) {
      starsNumber = 5;
    }
    j['star'] = (listsRepeat(0, starsNumber));
  }
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('fb8d504246919b5a0c961eba1dacffe2', testimonialsCards);
  ___arguments.context.pageData['counter'] = '0.0';
  ___arguments.context.pageData['isStarted'] = false;
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('ac4a977832adf413fc821584ff0ad028'))['display'] = 'none';

  },  
  /* handler:onEnter */
  /* handler:onScroll */
  async onScroll(___arguments) {
    var secondValue, firstValue, dot;

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setIntervals'); if (timerId && timers[timerId]) {    clearInterval(timers[timerId]);    delete timers[timerId]; }}

function runSetTimeout(timerId, callback, delay) {  const timers = getGlobalEntitiesMap('setIntervals'); const timer = setInterval(callback, delay); if (timerId) {  stopSetTimeout(timerId);  timers[timerId] = timer }}


  if (___arguments.scrollTop > ((((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('2418c4eb5e0915dfeafc7a27056c7a32'))['el'])['offsetTop']) - (await ( async function (typeSize){ return document.documentElement[`${typeSize}`] })('clientHeight'))) {
    if (!(___arguments.context.pageData['isStarted'])) {
      ___arguments.context.pageData['isStarted'] = true;
      firstValue = 0;
      secondValue = -1;
      dot = '.';

      ;(function() {
        const callback = async () => {
            secondValue = (typeof secondValue == 'number' ? secondValue : 0) + 1;
        if (secondValue == 10) {
          secondValue = 0;
          firstValue = (typeof firstValue == 'number' ? firstValue : 0) + 1;
        }
        ___arguments.context.pageData['counter'] = (firstValue + dot + secondValue);
        if (firstValue == 4 && secondValue == 8) {
          stopSetTimeout('count');
        }

        };

        const timerId = 'count';
        const timerDelay = 35;

        runSetTimeout(timerId, callback, timerDelay)
      })()
    }
  }

  },  
  /* handler:onScroll */
  /* content */
}))

define('./pages/hello/components/fae18ef3ccb1b9d21698e72cb2f89c96/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    
  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/hello/components/8feeb67a8526a9610161413cdcee27ff/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var error, userEmail;


  userEmail = (___arguments.context.pageData['email']);
  if (!userEmail) {
    ___arguments.context.pageData['message'] = 'Please enter your email';
    await new Promise(r => setTimeout(r, 250 || 0));
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f170648b166401bd6dab8410e392aa', true);
    await new Promise(r => setTimeout(r, 2000 || 0));
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f170648b166401bd6dab8410e392aa', false);
    return false;
  }
  try {
    ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('8feeb67a8526a9610161413cdcee27ff'))['disabled'] = true;
    ___arguments.context.pageData['btnlabel'] = 'Sending...';
    await Backendless.Data.of('Subscriber').save( ({ 'email': userEmail }) );
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8feeb67a8526a9610161413cdcee27ff', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6ba74516dcece599c4cda9cdfb0e48b9', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('074c75b20d76e225b092fbbee555a627', true);

  } catch (error) {
    console.log(error);
    ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('8feeb67a8526a9610161413cdcee27ff'))['disabled'] = false;
    ___arguments.context.pageData['btnlabel'] = 'Subscribe';
    if ((error['code']) == 8023) {
      ___arguments.context.pageData['message'] = 'Email address is not valid, please correct the email address and try again';
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f170648b166401bd6dab8410e392aa', true);
      await new Promise(r => setTimeout(r, 3500 || 0));
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f170648b166401bd6dab8410e392aa', false);
    }

  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/hello/components/6e8fd96497c55daf92b43161e71d2bf2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('course', ({ 'ID': (___arguments.context.getComponentDataStoreByUid('57455e38cb3fc31bc9a5e4e301b5bf4f')['objectId']) }));

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/hello/components/2091ca7b810f7fddf57d8c570df70c9b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('course', ({ 'ID': (___arguments.context.getComponentDataStoreByUid('57455e38cb3fc31bc9a5e4e301b5bf4f')['objectId']) }));

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/hello/components/96beb1e8c39f1ce10f673445106c7808/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('course', ({ 'ID': (___arguments.context.dataModel['objectId']) }));

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/hello/components/9955e9238106ee628c22981e49fe222a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (url, isExternal, params){ const targetUrl = `${url}?${BackendlessUI.QueryString.stringify(params)}`; isExternal ? window.open(targetUrl, '_blank') : window.location = targetUrl; })((String('mailto:') + String(___arguments.context.pageData['email'])), true, null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/hello/components/89fb35af24fe1da141df86e926182bc6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var phoneToCall, i, phone;


  phone = (___arguments.context.pageData['phone']);
  phoneToCall = '';
  var i_end = phone.length;
  var i_inc = 1;
  if (1 > i_end) {
    i_inc = -i_inc;
  }
  for (i = 1; i_inc >= 0 ? i <= i_end : i >= i_end; i += i_inc) {
    if (phone[(i - 1)] != '-') {
      phoneToCall = phoneToCall + phone[(i - 1)];
    }
  }
  ;await ( async function (url, isExternal, params){ const targetUrl = `${url}?${BackendlessUI.QueryString.stringify(params)}`; isExternal ? window.open(targetUrl, '_blank') : window.location = targetUrl; })((String('tel:') + String(phoneToCall)), true, null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/hello/components/7aa5176c3aa5b0c3e997eb210dc2cac9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('allCourses', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/hello/components/84f907bfbdd124929071a7b7db42cb35/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      if (((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('8921394d9d38d6ed2e067dead5833b95'))['open']) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8921394d9d38d6ed2e067dead5833b95', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bf4bdee7452889188b8d78327c42bf2d', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b4e3077bf4de34510ed2b4c3cb69fb4e', true);
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('8921394d9d38d6ed2e067dead5833b95'))['open'] = false;
  } else {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8921394d9d38d6ed2e067dead5833b95', true);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b4e3077bf4de34510ed2b4c3cb69fb4e', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bf4bdee7452889188b8d78327c42bf2d', true);
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('8921394d9d38d6ed2e067dead5833b95'))['open'] = true;
  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/hello/components/fa66b3d71af418b734b703c72c7b3895/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('home', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/hello/components/c39e9a22636901cad06585751042c92b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('allCourses', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/hello/components/ade753e641625aeb7c6c595a9b02f6a1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (url, isExternal, params){ const targetUrl = `${url}?${BackendlessUI.QueryString.stringify(params)}`; isExternal ? window.open(targetUrl, '_blank') : window.location = targetUrl; })((___arguments.context.dataModel['link']), true, null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/hello/components/6b263ab24803022b6b53c81fcb8f58c9/bundle.js', [], () => ({
  /* content */
  /* handler:onTargetPathAssignment */
  onTargetPathAssignment(___arguments) {
      return (String('https://maps.google.com/?q=') + String(___arguments.context.pageData['address']))

  },  
  /* handler:onTargetPathAssignment */
  /* content */
}))

define('./pages/jscharting/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async onEnter(___arguments) {
    var item;


  await (async function() {
  	var data = (await Promise.all((await Backendless.Data.of('City').find(Backendless.DataQueryBuilder.create().setWhereClause('CountryName is not null').setProperties(['Country.Name as CountryName', 'Count(Country) as NumberOfCities']).setGroupBy('Country').setPageSize(100))).map(async item => {; return ({ 'x': (item['CountryName']),'y': (item['NumberOfCities']) });})));

  	await BackendlessUI.requireModule( "https://code.jscharting.com/latest/jscharting.js" )
  	console.log( data )
  	const chart = new JSC.Chart("chartDiv", {
  	  // Pass points to the series
  	  series: [{ points: data }]
  	  // Set the x axis scale to time.
  	  //xAxis_scale_type: "time"
  	});

  })();

  },  
  /* handler:onEnter */
  /* content */
}))

define('./pages/landing/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  onEnter(___arguments) {
    
  },  
  /* handler:onEnter */
  /* content */
}))

define('./pages/landing1/components/e4d5e08cb04e001e19dd76425a229ef2/bundle.js', [], () => ({
  /* content */

  
/* handler:onPointsAssignment */
  onPointsAssignment(___arguments) {
      return [___arguments.context.getComponentDataStoreByUid('bb8cb81cda8ea9f1b0b75e93469dc08f')]

  },  
/* handler:onPointsAssignment *//* handler:onMapCenterAssignment */
  onMapCenterAssignment(___arguments) {
      return ___arguments.context.getComponentDataStoreByUid('bb8cb81cda8ea9f1b0b75e93469dc08f')

  },  
/* handler:onMapCenterAssignment *//* content */
}));

define('./pages/landing1/components/b874cc5477d7d76693695c7422d73bf1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('demo', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/landing1/components/0172de2e5299731d4831b03862f6c6db/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeUpload */
  onBeforeUpload(___arguments) {
    
  },  
  /* handler:onBeforeUpload */
  /* content */
}))

define('./pages/landing1/components/page/bundle.js', [], () => ({
  /* content */

  

/* handler:onEnter */
  async ['onEnter'](___arguments) {
      console.log((await (async function() {
  	const params = new URLSearchParams(window.location.search)

  	return params.get('page');
  })()));

  },
  /* handler:onEnter */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var key, obj;

/**
 * Describe this function...
 */
async function get_object_from_collection() {
  return (await Backendless.Data.of('YourTableName').find(Backendless.DataQueryBuilder.create().setWhereClause('your where clause goes here').setPageSize(1)))[0]
}

/**
 * Describe this function...
 */
async function delete_all_props(obj) {
  var key_list = (Object.keys(obj));
  for (var key_index in key_list) {
    key = key_list[key_index];
    delete obj[key];
  }
}

/**
 * Describe this function...
 */
async function update_linestring() {
  (new Backendless.Data.LineString((new Backendless.Data.Point().setX(0).setY(0)))).setPoints((new Backendless.Data.Point().setX(1).setY(1)));
}


  console.log((new Date()).toUTCString());

  },
  /* handler:onBeforeEnter */
  /* handler:onLeave */
  ['onLeave'](___arguments) {
      ;(async () => {
      console.log('abc');

  })();

  },
  /* handler:onLeave */
  /* handler:onPageQuery */
  async ['onPageQuery'](___arguments) {
      console.log(await Backendless.Hive('').ListStore('').length());

  },
  /* handler:onPageQuery */
  /* handler:onScroll */
  async ['onScroll'](___arguments) {
    var anyValue;


  await Backendless.Hive('').MapStore('').set(({ 'keyName': anyValue }));

  },
  /* handler:onScroll */
  /* content */
}));

define('./pages/landing1/components/0d8ae05d9245ce0c46730c966fb997d1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ;(function (componentUid, value){  ___arguments.context.getComponentByUid(componentUid).setValue(value) })('11f4c9195324be94721a200929bc7e8b', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).getValue() })('11f4c9195324be94721a200929bc7e8b')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing1/components/0ef544255349a94af56b21a66b727e2e/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  onDynamicItemsAssignment(___arguments) {
    
  },  
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/landing1/components/76c1b8e810b6d0f59cd30f8a0eec614a/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async onMounted(___arguments) {
      ;await ( async function (message){ alert(message) })(___arguments.context.dataModel);

  },  
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing1/components/52630c6fb821b8dd4f26c5fb007aec36/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  onChange(___arguments) {
    
  },
  /* handler:onChange */
  /* handler:onKeyDown */
  onKeyDown(___arguments) {
    
  },
  /* handler:onKeyDown */
  /* content */
}))

define('./pages/landing1/components/a388a77eef316e03804306f7205714af/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  onSourceUrlAssignment(___arguments) {
      return ((___arguments.context.getComponentDataStoreByUid('9f5421db16621ab3cc3794bfa366b561')['image']) == null ? ((___arguments.context.getComponentDataStoreByUid('9f5421db16621ab3cc3794bfa366b561')['category'])['category_image']) : (___arguments.context.getComponentDataStoreByUid('9f5421db16621ab3cc3794bfa366b561')['image']))

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/landing1/components/e224bda7ee2eafdd9cedd00e8f3eb193/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    
  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing1/components/67668f6d193ee9be712b688c43dfc40b/bundle.js', [], () => ({
  /* content */
  /* handler:onVisibilityChange */
  ['onVisibilityChange'](___arguments) {
    
  },
  /* handler:onVisibilityChange */
  /* content */
}))

define('./pages/layoutTest/components/4a491b59c7993ed4665dabbd801fab96/bundle.js', [], () => ({
  /* content */

  /* handler:onChange */
  onChange(___arguments) {
      ___arguments.context.appData['v'] = ___arguments.value;

  },  
/* handler:onChange *//* content */
}));

define('./pages/loadcss/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async onEnter(___arguments) {
      await BackendlessUI.Functions.Custom['fn_252e8ac75e86ca1c273b20bc4a3ecbea']('https://unpkg.com/ag-grid-community/dist/styles/ag-grid.css')

  },  
  /* handler:onEnter */
  /* content */
}))

define('./pages/login/components/85dae34486144e2efa2d934bfdddb172/bundle.js', [], () => ({
  /* content */

  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var user, error, password, username, stayLoggedIn, logging, buttonLogin;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function login() {
  try {
    user = (await Backendless.UserService.login( username, password, stayLoggedIn  ));

  } catch (error) {
    await setError(error);

  }
}

/**
 * Describe this function...
 */
async function setError(error) {
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('85dae34486144e2efa2d934bfdddb172'))['error'] = error;
}

/**
 * Describe this function...
 */
async function updateButtonLogin(logging) {
  buttonLogin = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'));
  buttonLogin['label'] = (logging ? 'Please wait...' : 'Login');
  buttonLogin['disabled'] = logging;
}


  await setError(null);
  username = (getObjectProperty(___arguments.context.dataModel, 'username'));
  password = (getObjectProperty(___arguments.context.dataModel, 'password'));
  stayLoggedIn = (getObjectProperty(___arguments.context.dataModel, 'stayLoggedIn'));
  if (username && password) {
    await updateButtonLogin(true);
    try {
      await login();

    } catch (error) {
      if ((getObjectProperty(error, 'code')) == 3033) {
        await login();
      } else {
        await setError(error);
      }

    } finally {
      await updateButtonLogin(false);

    }
  } else {
    await setError((new Error('Username and Password must be filled')));
  }
  if (user) {
    ___arguments.context.appData['currentUser'] = user;
  }

  },
  /* handler:onSubmit *//* content */
}));

define('./pages/login/components/f1c849acfb0e032e45aee6fbafea0748/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      return (await Backendless.Data.of('City').find(Backendless.DataQueryBuilder.create().setPageSize(10)));

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/loginPage/components/85dae34486144e2efa2d934bfdddb172/bundle.js', [], () => ({
  /* content */

  /* handler:onSubmit */
  async onSubmit(___arguments) {
    var user, error, password, username, stayLoggedIn, logging, buttonLogin;

/**
 * Describe this function...
 */
async function login() {
  try {
    user = (await Backendless.UserService.login( username, password, stayLoggedIn  ));

  } catch (error) {
    await setError(error);

  }
}

/**
 * Describe this function...
 */
async function setError(error) {
  ((function (componentUid){ return ___arguments.context.getComponentDataStoreByUid(componentUid) })('85dae34486144e2efa2d934bfdddb172'))['error'] = error;
}

/**
 * Describe this function...
 */
async function updateButtonLogin(logging) {
  buttonLogin = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'));
  buttonLogin['label'] = (logging ? 'Please wait...' : 'Login');
  buttonLogin['disabled'] = logging;
}


  await setError(null);
  username = (___arguments.context.dataModel['username']);
  password = (___arguments.context.dataModel['password']);
  stayLoggedIn = (___arguments.context.dataModel['stayLoggedIn']);
  if (username && password) {
    await updateButtonLogin(true);
    try {
      await login();

    } catch (error) {
      if ((error['code']) == 3033) {
        await login();
      } else {
        await setError(error);
      }

    } finally {
      await updateButtonLogin(false);

    }
  } else {
    await setError((new Error('Username and Password must be filled')));
  }
  if (user) {
    ___arguments.context.appData['currentUser'] = user;
  }

  },  
  /* handler:onSubmit *//* content */
}));

define('./pages/loginPage/components/3eafb1951c8d83bf1d4a39459dfb70cf/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      console.log(await Backendless.Data.of('Users').findFirst());

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/logindemo/components/85dae34486144e2efa2d934bfdddb172/bundle.js', [], () => ({
  /* content */

  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, stayLoggedIn, password, username, user, logging, buttonLogin;

/**
 * Describe this function...
 */
async function login() {
  try {
    user = (await Backendless.UserService.login( username, password, stayLoggedIn  ));

  } catch (error) {
    await setError(error);

  }
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setError(error) {
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('85dae34486144e2efa2d934bfdddb172'))['error'] = error;
}

/**
 * Describe this function...
 */
async function updateButtonLogin(logging) {
  buttonLogin = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'));
  buttonLogin['label'] = (logging ? 'Please wait...' : 'Login');
  buttonLogin['disabled'] = logging;
}


  await setError(null);
  username = (getObjectProperty(___arguments.context.dataModel, 'username'));
  password = (getObjectProperty(___arguments.context.dataModel, 'password'));
  stayLoggedIn = (getObjectProperty(___arguments.context.dataModel, 'stayLoggedIn'));
  if (username && password) {
    await updateButtonLogin(true);
    try {
      await login();

    } catch (error) {
      if ((getObjectProperty(error, 'code')) == 3033) {
        await login();
      } else {
        await setError(error);
      }

    } finally {
      await updateButtonLogin(false);

    }
  } else {
    await setError((new Error('Username and Password must be filled')));
  }
  if (user) {
    ___arguments.context.appData['currentUser'] = user;
  }

  },
  /* handler:onSubmit *//* content */
}));

define('./pages/lottieDemo/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async onEnter(___arguments) {
      await (async function() {
  	await BackendlessUI.requireModule('https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js')

  	const el = document.querySelector('.animation')

  	el.innerHTML = `
  	<lottie-player src="https://assets5.lottiefiles.com/datafiles/zc3XRzudyWE36ZBJr7PIkkqq0PFIrIBgp4ojqShI/newAnimation.json"  background="transparent"  speed="1"  style="width: 300px; height: 300px;"  loop  autoplay></lottie-player>
  	<lottie-player src="https://assets9.lottiefiles.com/datafiles/MUp3wlMDGtoK5FK/data.json"  background="transparent"  speed="1"  style="width: 300px; height: 300px;"  loop  autoplay></lottie-player>
  	<lottie-player src="https://assets1.lottiefiles.com/datafiles/HN7OcWNnoqje6iXIiZdWzKxvLIbfeCGTmvXmEm1h/data.json"  background="transparent"  speed="1"  style="width: 300px; height: 300px;"  loop  autoplay></lottie-player>
  	`
  })();

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/mapbox/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async onEnter(___arguments) {
      await BackendlessUI.Functions.Custom['fn_252e8ac75e86ca1c273b20bc4a3ecbea']('https://api.mapbox.com/mapbox-gl-js/v2.5.0/mapbox-gl.css')
  await (async function() {
  	const mapboxgl = await BackendlessUI.requireModule( "https://api.mapbox.com/mapbox-gl-js/v2.5.0/mapbox-gl.js" )

  	mapboxgl.accessToken = 'pk.eyJ1IjoibWFya3BpbGxlciIsImEiOiJja3U4N29qbmwwNm9hMm5wN2dteDZ0Nm0wIn0.NreJiJ8EtNk6WZ1vpsn74Q';
  	const map = new mapboxgl.Map({
  	  container: 'map', // container ID
  	  style: 'mapbox://styles/mapbox/streets-v11', // style URL
  	  center: [-74.5, 40], // starting position [lng, lat]
  	  zoom: 9 // starting zoom
  	});
  })();

  },  
  /* handler:onEnter */
  /* content */
}))

define('./pages/particles/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async onEnter(___arguments) {
      await (async function() {
  	const p = await BackendlessUI.requireModule( "https://cdn.jsdelivr.net/particles.js/2.0.0/particles.min.js" )
  	const s = await BackendlessUI.requireModule( "https://threejs.org/examples/js/libs/stats.min.js" )

  	particlesJS("particles-js", {
  	  "particles": {
  	    "number": {
  	      "value": 380,
  	      "density": {
  	        "enable": true,
  	        "value_area": 800
  	      }
  	    },
  	    "color": {
  	      "value": "#ffffff"
  	    },
  	    "shape": {
  	      "type": "circle",
  	      "stroke": {
  	        "width": 0,
  	        "color": "#000000"
  	      },
  	      "polygon": {
  	        "nb_sides": 5
  	      },
  	      "image": {
  	        "src": "img/github.svg",
  	        "width": 100,
  	        "height": 100
  	      }
  	    },
  	    "opacity": {
  	      "value": 0.5,
  	      "random": false,
  	      "anim": {
  	        "enable": false,
  	        "speed": 1,
  	        "opacity_min": 0.1,
  	        "sync": false
  	      }
  	    },
  	    "size": {
  	      "value": 3,
  	      "random": true,
  	      "anim": {
  	        "enable": false,
  	        "speed": 40,
  	        "size_min": 0.1,
  	        "sync": false
  	      }
  	    },
  	    "line_linked": {
  	      "enable": true,
  	      "distance": 150,
  	      "color": "#ffffff",
  	      "opacity": 0.4,
  	      "width": 1
  	    },
  	    "move": {
  	      "enable": true,
  	      "speed": 6,
  	      "direction": "none",
  	      "random": false,
  	      "straight": false,
  	      "out_mode": "out",
  	      "bounce": false,
  	      "attract": {
  	        "enable": false,
  	        "rotateX": 600,
  	        "rotateY": 1200
  	      }
  	    }
  	  },
  	  "interactivity": {
  	    "detect_on": "canvas",
  	    "events": {
  	      "onhover": {
  	        "enable": true,
  	        "mode": "grab"
  	      },
  	      "onclick": {
  	        "enable": true,
  	        "mode": "push"
  	      },
  	      "resize": true
  	    },
  	    "modes": {
  	      "grab": {
  	        "distance": 140,
  	        "line_linked": {
  	          "opacity": 1
  	        }
  	      },
  	      "bubble": {
  	        "distance": 400,
  	        "size": 40,
  	        "duration": 2,
  	        "opacity": 8,
  	        "speed": 3
  	      },
  	      "repulse": {
  	        "distance": 200,
  	        "duration": 0.4
  	      },
  	      "push": {
  	        "particles_nb": 4
  	      },
  	      "remove": {
  	        "particles_nb": 2
  	      }
  	    }
  	  },
  	  "retina_detect": true
  	});


  	/* ---- stats.js config ---- */

  	var count_particles, stats, update;
  	stats = new Stats;
  	stats.setMode(0);
  	stats.domElement.style.position = 'absolute';
  	stats.domElement.style.left = '0px';
  	stats.domElement.style.top = '0px';
  	document.body.appendChild(stats.domElement);
  	count_particles = document.querySelector('.js-count-particles');
  	update = function() {
  	  stats.begin();
  	  stats.end();
  	  if (window.pJSDom[0].pJS.particles && window.pJSDom[0].pJS.particles.array) {
  	    count_particles.innerText = window.pJSDom[0].pJS.particles.array.length + " particles"
  	  }
  	  requestAnimationFrame(update);
  	};
  	requestAnimationFrame(update);
  })();

  },  
  /* handler:onEnter */
  /* content */
}))

define('./pages/reactpackaging/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async onEnter(___arguments) {
      await (async function() {
  	const startMyModule = await BackendlessUI.requireModule('modules/main')
  	startMyModule()

  })();

  },  
  /* handler:onEnter */
  /* content */
}))

define('./pages/realtime/components/65bc36dd7745e281f83d8fa92a025afc/bundle.js', [], () => ({
  /* content */
  /* handler:onKeyDown */
  async onKeyDown(___arguments) {
      if (___arguments.keyCode == 13) {
    await Backendless.Data.of('SillyEntry').save( ({ 'name': (___arguments.context.pageData['text']) }) );
    ___arguments.context.pageData['text'] = null;
  }

  },  
  /* handler:onKeyDown */
  /* content */
}))

define('./pages/realtime/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  onEnter(___arguments) {
    var list, object;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function getRTSubscriptions() { const subs = getGlobalEntitiesMap('subscriptions');subs.map = subs.map || {};subs.remove = subs.remove || (subId => { if (subs.map[subId]) { subs.map[subId](); delete subs.map[subId];} });subs.add = subs.add || ((subId, callback) => {    if (subs.map[subId]) { throw new Error(`RT subscription with ID: "${subId}" already exists.`) }    subs.map[subId] = callback; });return subs;}


  ___arguments.context.pageData['listOfEntries'] = [];

  ;(function() {
    const callback = async object => {
        console.log(object);
    list = (___arguments.context.pageData['listOfEntries']);
    addItemToList(list, (object['name']));
    ___arguments.context.pageData['listOfEntries'] = list;

    };

    const rtHandlers = Backendless.Data.of('SillyEntry').rt();

    const whereClause = '';
    const options = [callback];

    if (whereClause) {
       options.unshift(whereClause);
    }

    rtHandlers.addCreateListener.apply(rtHandlers, options);

    getRTSubscriptions().add('mylistener', () => rtHandlers.removeCreateListener(callback));

  })()

  },  
  /* handler:onEnter */
  /* content */
}))

define('./pages/realtime/components/90c6d08193fb15b2cb929ac9aeb4a494/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  onContentAssignment(___arguments) {
      return ___arguments.context.dataModel

  },  
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/scalefixedcalc/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_1b77dd78f5b3ff799d156cab4c43069d'](___arguments.context.pageData);

  },
  /* handler:onEnter */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
      ___arguments.context.pageData['baseFee'] = 0.05;
  (function (componentUid, value) { ___arguments.context.getComponentByUid(componentUid).value = value })('10b938c36297a5ca417842d19aa10ac3', 1);

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/scalefixedcalc/components/10b938c36297a5ca417842d19aa10ac3/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var prices, i, baseFee;


  await BackendlessUI.Functions.Custom['fn_1b77dd78f5b3ff799d156cab4c43069d'](___arguments.context.pageData);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/scalefixedcalc/components/38c6fe49f3e1ae92fa28ffcff716b79d/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      if (___arguments.value != 0) {
    await BackendlessUI.Functions.Custom['fn_1b77dd78f5b3ff799d156cab4c43069d'](___arguments.context.pageData);
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/scalefixedcalculator/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_1b77dd78f5b3ff799d156cab4c43069d'](___arguments.context.pageData);

  },
  /* handler:onEnter */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
      ___arguments.context.pageData['baseFee'] = 0.15;
  (function (componentUid, value) { ___arguments.context.getComponentByUid(componentUid).value = value })('10b938c36297a5ca417842d19aa10ac3', 1);

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/scalefixedcalculator/components/10b938c36297a5ca417842d19aa10ac3/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var prices, i, baseFee;


  await BackendlessUI.Functions.Custom['fn_1b77dd78f5b3ff799d156cab4c43069d'](___arguments.context.pageData);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/scalefixedcalculator/components/38c6fe49f3e1ae92fa28ffcff716b79d/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      if (___arguments.value != 0) {
    await BackendlessUI.Functions.Custom['fn_1b77dd78f5b3ff799d156cab4c43069d'](___arguments.context.pageData);
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/simpleMath/components/d45c4adea4c9441e3d2ba7334d27df0e/bundle.js', [], () => ({
  /* content */

  /* handler:onContentAssignment */
  async onContentAssignment(___arguments) {
      return (String('Sum of X and Y is: ') + String(await BackendlessUI.Functions.Custom['addArgs']((___arguments.context.pageData['x']), (___arguments.context.pageData['y']))))

  },  
/* handler:onContentAssignment *//* content */
}));

define('./pages/simpleMath/components/a8e78da7caaa46cd7e66876713e84cfe/bundle.js', [], () => ({
  /* content */

  /* handler:onValueAssignment */
  onValueAssignment(___arguments) {
    
  },  
/* handler:onValueAssignment *//* content */
}));

define('./pages/simpleMath/components/dd2d7f87ee313a5b8c0108f5cd3c4a0c/bundle.js', [], () => ({
  /* content */

  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    
  },  
/* handler:onDisplayAssignment *//* content */
}));

define('./pages/tableDemoWithFilter/components/page/bundle.js', [], () => ({
  /* content */

  /* handler:onEnter */
  onEnter(___arguments) {
      ___arguments.context.pageData['tableOptions'] = ({ 'title': 'My Table','whereClause': 'objectId != null','realTime': true,'searching': true,'sorting': true,'paging': true,'allowAdd': true,'allowEdit': true,'allowDelete': true });

  },  
/* handler:onEnter *//* content */
}));

define('./pages/tableNameBidningBug/components/page/bundle.js', [], () => ({
  /* content */

  

/* handler:onEnter */
  async onEnter(___arguments) {
      ___arguments.context.pageData['countries'] = (await Backendless.Data.of('Country').find(Backendless.DataQueryBuilder.create().setWhereClause('continent = \'North America\'').setPageSize(100)));

  },  
/* handler:onEnter *//* content */
}));

define('./pages/tableNameBidningBug/components/e6295425d243d046ab78f973455d300c/bundle.js', [], () => ({
  /* content */

  
/* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['tableName'] = 'City';

  },  
/* handler:onClick *//* content */
}));

define('./pages/test/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var finalList, j, accumulator, inputList, result, k, number, stop2, sum, curValue, list;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function group_numbers(inputList) {
  finalList = [];
  accumulator = [];
  for (var j_index in inputList) {
    j = inputList[j_index];
    if (!(typeof (j * 1) === 'number' && !isNaN((j * 1)))) {
      if (!!accumulator.length) {
        finalList.push(accumulator);
        accumulator = [];
      }
      continue;
    } else {
      addItemToList(accumulator, (j * 1));
    }
  }
  return finalList
}

/**
 * Describe this function...
 */
async function what_should_this_function_be_called_(number) {
  result = true;
  if (number <= 1) {
    result = false;
  } else {
    k = 2;
    stop2 = Math.sqrt(number);
    while (k <= stop2) {
      if (number % k == 0) {
        result = false;
        break;
      }
      k = k + 1;
    }
  }
  return result
}

/**
 * Describe this function...
 */
async function quiz1() {
  console.log(await guess_what_I_do('1,2,3,4,5,6'.split(',')));
}

/**
 * Describe this function...
 */
async function guess_what_I_do(list) {
  sum = 0;
  var k_end = list.length;
  var k_inc = 1;
  if (1 > k_end) {
    k_inc = -k_inc;
  }
  for (k = 1; k_inc >= 0 ? k <= k_end : k >= k_end; k += k_inc) {
    curValue = list[(k - 1)];
    if (curValue % 2 == 0) {
      sum = curValue * 1 + sum;
    }
  }
  return sum
}


  console.log(await group_numbers('\'no\',\'no\',1,2,3,4,\'no\',\'no\',\'no\',5,6,7,\'no\''.split(',')));

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/test1/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var item, list, myList, priority, taskName, value;

/**
 * Describe this function...
 */
async function insert_sorted(list, taskName, priority) {
  value = ({ [`taskName`]: taskName,[`priority`]: priority });
  list.splice((((await BackendlessUI.Functions.Custom['fn_4cf12a1449b890f2c42b8fe2a753bc14'](list, value, 'priority')) + 1) - 1), 0, value);
}

/**
 * Describe this function...
 */
async function do_something() {
  myList = [];
  await insert_sorted(myList, 'Cleaning', 3);
  await insert_sorted(myList, 'Play with kids', 1);
  await insert_sorted(myList, 'Workout', 2);
  console.log(myList);
}


  myList = '1,2,3,4,5'.split(',');
  myList = (await Promise.all(myList.map(async item => {; return (item * 2);})));
  console.log(myList);

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/test1/components/370d4372225e071b841385bf8d7abacc/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  onContentAssignment(___arguments) {
      return ___arguments.context.getComponentDataStoreByUid('b9cd022e4f06754268a22f1d49e66814')

  },  
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/testWithVova/components/page/bundle.js', [], () => ({
  /* content */

  /* handler:onEnter */
  onEnter(___arguments) {
      ___arguments.context.pageData['tableOptions'] = ({ 'title': 'My Table','whereClause': 'objectId != null','realTime': true,'searching': true,'sorting': true,'paging': true,'allowAdd': true,'allowEdit': true,'allowDelete': true });

  },  
/* handler:onEnter *//* content */
}));

define('./components/reusable/r_194cc306eb48500b695743a6723dc3d6/logic/cc44a5f60012fd77dcd92c7904c5df2d/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
      console.log('component is running');

  },
  /* handler:onMounted */
  /* content */
}))

